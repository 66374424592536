.article
  position relative
  bg #000
  &:after
    content ''
    position absolute
    top 0
    left 0
    h 100%
    w 100%
    bg-color rgba(0,0,0,.7)
    +media(from('xl'))
      display none
  &__title
    box-sizing border-box
    ma 0 auto
    mb 20px
    fs 16px
    color $blue
    lh 1.4
    +media(from('xl'))
      fs 20px
      lh 1.3
      mb 40px
    +media(from('xl2'))
      fs 26px
  h2
    color #fff
  &__part
    display flex
    justify-content center
    pa 0 2.5vw
    ma 50px 2.5vw 30px 2.5vw
    h calc(100% - 56px) !important
    box-sizing border-box
    position relative
    z-index 2
    +media(from('md'))
      h calc(100% - 53px) !important
    +media(from('xl'))
      pl 35.5%
      h 100% !important
      ma 50px auto 50px auto
  &__image
    position absolute
    top 0
    left 0
    h 100%
    bg-position 50%
    w 100%
    bg-size cover
    bg-repeat no-repeat
    +media(from('xl'))
      w 32.5%
  &__wrapper
    h 100%
    overflow auto
    max-width 1100px
    pa 0 10px
    &::-webkit-scrollbar
      w 4px
      +media(from('xl'))
        w 12px
    &::-webkit-scrollbar-track
      border-radius 10px 
    &::-webkit-scrollbar-thumb
      background #343434
      border-radius 10px
  &__content
    font-family 'Open Sans', Tahoma
    color #fff
    float left
    font-weight 300
    font-size 16px
    +media(from('md'))
      pr 16px
    +media(from('xl2'))
      fs 18px
    +media(from('fhd'))
      fs 20px
