.logo
  h 52px
  top 10px
  position absolute
  border 6px solid #000
  bg-color #000
  border-radius 30px
  left 50%
  transform translateX(-50%)
  z-index 3
  display flex
  +media(from('md'))
    h 110px
    border 14px solid #000
    top 4px
    border-radius 40px
  +media(from('xl'))
    border 0
    border-radius 0
    h 100%
    max-height 50px
    position relative
    left 0
    transform none
  img
    h 100%