.main-content
  width 100%
  height calc(100% - 37px)
  z-index 200
  position absolute
  left 0px
  top 37px
  display flex
  flex-direction column
  justify-content space-between
  ta left
  +media(from('md'))
    top 61px
    height calc(100% - 61px)
  +media(from('xl'))
    top 78px
    height calc(100% - 78px)
  &--bg
    bg-size cover
    bg-repeat no-repeat
  &__title
    color #fff
    position absolute
    z-index 2
    w 188px
    pa 7px 20px
    h auto
    box-sizing border-box
    fs 16px
    line-height 19px
    bg rgba(0,0,0,.7)
    bottom 191px
    ma 0
    ta left
    letter-spacing 2px
    +media(from('md'))
      lh 33px
      bottom 170px
      pa 35px 39px 20px 39px
      bg #000
      w 571px
      fs 28px
    +media(from('xl'))
      display flex
      justify-content center
      align-items center
      pa 0 20px
      ta center
      h 10vh
      ml 2.5vw
      w 30vw
      position absolute
      top 0
      bg rgba(0,0,0,.8)
      fs 22px
    +media(from('fhd'))
      pa 0 30px
      fs 28px
    &.is-full
      display flex
      bottom 70%
    &--template
      &.is-full
        w 100%
        +media(from('md'))
          w 100%
          pa 15px 39px 15px
          bg rgba(0,0,0,.7)
        +media(from('xl'))
          w 30vw
          pa 0 20px
    &--full
      position relative
      bottom 0
      width 100%
      pa 0
      mb 25px
      mt 25px
      display block
      +media(from('md'))
        bottom 0
        pa 90px 0 20px 0
        w 100%
      +media(from('xl'))
        display none
    &--gallery
      +media(from('xl'))
        display none
    &--article
      position relative
      +media(from('xl'))
        display none
    &--contacts,
    &--full-w
      bg-color #000
      top 0
      w 100%
      bottom auto
      pt 30px
      ta center
      +media(from('md'))
        top 0
        pt 50px
        ta center
        w 100%
        bottom auto
      +media(from('xl'))
        bg-color #000
    &--full-w
      +media(from('xl'))
        bg rgba(0,0,0,.8)
  &__back
    position absolute
    right 20px
    z-index 2
    cursor pointer
    top 29px
    w 16px
    h 16px
    +media(from('md'))
      top 54px
      w 25px
      h 25px
    +media(from('xl'))
      right 40px
      top 5px
      w 31px
      h 31px
    img
      w 100%
  &__image
    bg-size cover
    background-position 50%
    background-repeat no-repeat
    position absolute
    w 100%
    h 100%
    top 0
    left 0
  &__info
    pa 20px
    pb 30px
    position absolute
    bottom 0
    z-index 2
    color #fff
    box-sizing border-box
    h 191px
    bg #000
    +media(from('md'))
      w 571px
      pa 20px 39px 29px 39px
      h 170px
    +media(from('xl'))
      pa 10px 20px
      bg rgba(0,0,0,.8)
      ma 0 2.5vw
      width 30vw
      h auto
    +media(from('fhd'))
      h auto
      pa 20px 30px
    &.is-hidden
      display none
    &.is-full
      bg #000
      h 70%
      +media(from('md'))
        box-sizing border-box
        w 100%
      +media(from('xl'))
        max-width 100%
        left auto
        pa 20px 2.5vw
        w 67.5vw
        h calc(90vh - 78px)
        right -2.5vw
      +media(from('fhd'))
        pa 44px 2.5vw
      .main-content
        &__description
          &--full
            overflow auto
            h 100%
            display block
          &--common
            display none
        &__wrapper
          +media(from('xl'))
            pr 44px
        &__title
          &--full
            +media(from('md'))
              display block
            +media(from('xl'))
              display none
        &__more
          display none
        h2, h3,
        &__heading
          overflow visible
          h auto
    &--dark
      bg-color #000
    &--contacts
      w 100%
      h calc(100vh - 93px)
      overflow auto
      ma 0
      w 100%
      pa 0
      +media(from('md'))
        pa 0
        w 100%
        h calc(100vh - 162px)
      +media(from('xl'))
        right -2.5vw
        bg-color #000
        display flex
        h calc(100vh - 10vh - 78px)
        flex-direction column
        justify-content space-between
        max-width 100%
        w 67.5vw
      +media(from('xl2'))
        h calc(100vh - 10vh - 78px)
      +media(from('fhd'))
        h calc(100vh - 10vh - 78px)
      &::-webkit-scrollbar
        w 4px
        +media(from('xl'))
          w 12px
      &::-webkit-scrollbar-track
        border-radius 10px 
      &::-webkit-scrollbar-thumb
        background #343434
        border-radius 10px
  h2, h3,
  &__heading,
  &__more
    color $blue
  h2, h3,
  &__heading
    ma 0
    mb 10px
    fs 16px
    lh 1.4
    +media(from('xl'))
      fs 20px
    +media(from('xl2'))
      fs 26px
    +media(from('fhd'))
      mb 30px
  &__heading
    &--white
      color #fff
    &--contacts
      display none
      +media(from('xl'))
        display block
    &--hidden
      lh 1.3
      overflow hidden
      text-overflow ellipsis
      display -webkit-box
      -webkit-line-clamp 2
      -webkit-box-orient vertical
      +media(from('xl'))
        max-h 68px
  &__smart-info
    overflow hidden
    text-overflow ellipsis
    display -webkit-box
    -webkit-line-clamp 2
    -webkit-box-orient vertical
    +media(from('xl'))
      max-h 50px
    +media(from('fhd'))
      max-h 57px
  &__row
    +media(from('xl'))
      display flex
      flex-direction row
      align-items center
      justify-content space-between
  &__map
    w 100%
    mb 20px
    mt 20px
    h 280px
    +media(from('md'))
      h 300px
    +media(from('xl'))
      w 45%
    +media(from('fhd'))
      w 50%
      h 500px
    @media (max-width: 992px) and (orientation: landscape)
      ma 0 auto 20px auto
      w 80%
  &__copyright
    display none
    letter-spacing 0.27px
    fs 10px
    color #868686
    +media(from('xl'))
      display block
    p
      ma 0
  &__description
    fs 16px
    +media(from('xl2'))
      fs 18px
    +media(from('fhd'))
      fs 20px
    &--full
      h calc(100% - 52px)
      display none
      max-height 90%
      overflow hidden
      +media(from('md'))
        max-height 58vh
      +media(from('lg'))
        h 73%
      +media(from('xl'))
        max-height 85%
        h 85%
      +media(from('xl2'))
        max-height 85%
        h 85%
      +media(from('fhd'))
        max-height 85%
        h 85%
      &::-webkit-scrollbar
        w 4px
        +media(from('xl'))
          w 12px
      &::-webkit-scrollbar-track
        border-radius 10px 
      &::-webkit-scrollbar-thumb
        background #343434
        border-radius 10px
    p
      ma 0
    p + p
      mt 20px
  &__more
    fs 16px
    pt 20px
    display inline-block
    +media(from('lg'))
      pt 10px
    +media(from('xl2'))
      pt 30px
    &:hover
      +media(from('xl'))
        text-decoration underline
