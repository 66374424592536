.default-slider
  h 100%
  .owl-wrapper-outer,
  .owl-wrapper,
  .owl-item
    h 100%
  .owl-item
    opacity 1 !important
    display flex
    align-items flex-end
    &.active
      opacity 1 !important
  &__item
    display flex
    align-items flex-end
    h calc(100% - 88px)
    +media(from('xl'))
      h calc(100% - 73px)
    +media(from('fhd'))
      h calc(100% - 132px)
    &--contacts
      h 100%
      +media(from('xl'))
        h 100%
      +media(from('fhd'))
        h 100%
  .owl-controls
    w 100%
    position absolute
    display none !important
    +media(from('xl'))
      display block !important
  .owl-pagination
    pr 80px
    top -26px
    z-index 2
    display flex
    position absolute
    justify-content flex-end
    left auto !important
    right 0
    @media (max-width: 992px) and (orientation: landscape)
      left auto !important
      right 0
    @media (max-width: 992px) and (orientation: portrait)
      left auto !important
      right 0
    +media(from('xl'))
      top -35px
    +media(from('fhd'))
      top -70px
  .owl-buttons
    position absolute
    w 100%
    top -50vh
    .owl-prev
      bg url('./../img/prev-slide.png') no-repeat
      left 5px
    .owl-next
      bg url('./../img/next-slide.png') no-repeat
      right 5px
    .owl-prev,
    .owl-next
      border 0
      top 0
      h 39px
      bg-size contain
      w 12px
      +media(from('md'))
        h 61px
        w 19px
      +media(from('fhd'))
        h 100px
        w 30px
  .owl-numbers
    display none
  .owl-page
    w 18px
    h 18px
    border 2px solid #fff
    position relative
    border-radius 50%
    box-sizing border-box
    ml 15px
    +media(from('fhd'))
      w 22px
      h 22px
    &:first-child
      ml 0
    &.active
      &:before
        content ''
        position absolute
        border-radius 50%
        top 50%
        left 50%
        transform translate(-50%, -50%)
        bg-color $blue
        h 10px
        w 10px
        +media(from('fhd'))
          h 13px
          w 13px