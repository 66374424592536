.hamburger
  position absolute
  h 10px
  cursor pointer
  z-index 100
  display block
  top 50%
  transform translateY(-50%)
  left 16px
  w 18px
  +media(from('md'))
    w 37px
    h 22px
  +media(from('xl'))
    display none
  &.is-active
    .hamburger
      &__line
        transition .5s all ease
        &:nth-child(2)
          w 0
        &:first-child,
        &:last-child
          top 50%
        &:first-child
          transform translate(-50%, -50%) rotate(45deg)    
        &:last-child
          transform translate(-50%, -50%) rotate(-45deg)
  &__line
    position absolute
    left 50%
    w 100%
    transition .5s all ease
    transform translateX(-50%)
    bg-color #fff
    h 2px
    +media(from('xl'))
      h 3px
    +media(from('xl'))
      bg-color $blue
    &:first-child
      top 0
    &:nth-child(2)
      top 4px
      +media(from('md'))
        top 9px
    &:last-child
      top 8px
      +media(from('md'))
        top 19px
