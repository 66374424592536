.grid-sizer,
.grid-item
  w 100%
  @media screen and (max-width: 992px) and (min-width: 544px) and (orientation: portrait)
    w 50%
  @media (max-width: 992px) and (min-height: 400px) and (orientation: landscape)
    w 50%
  @media (max-width: 992px) and (max-height: 400px) and (orientation: landscape)
    w 50%
  @media (max-width: 1200px) and (min-width: 993px)
    w 33.33%
  +media(from('xl'))
    w 33.33%
.grid-item
  h auto
  overflow hidden
  cursor pointer
  img
    transition 1s all ease
    display block
    w 100%
    h 100%
  &:hover
    +media(from('xl'))
      img
        transition 1s all ease
        transform scale(1.2)

.gallery
  mt 56px!important
  h calc(100% - 56px) !important
  +media(from('md'))
    mt 103px !important
    h calc(100% - 103px) !important
  +media(from('xl'))
    mt 0 !important
    h 100% !important
  &--scroll
    overflow auto
    &::-webkit-scrollbar
      w 0
    &::-webkit-scrollbar-thumb
      background transparent
  &,
  &__wrapper,
  & .fancybox
    w 100%
    h 100%
  &__content
    ta left
    transition .3s height ease
    bg rgba(0,0,0,.8)
    position absolute
    z-index 2
    bottom 0
    left 0
    w 100%
    box-sizing border-box
    h 100%
    pa 10px 15px 10px
    h 90px
    +media(from('md'))
      h 107px
    +media(from('xl'))
      h 92px
      pt 20px
    +media(from('fhd'))
      h 107px
      pb 6px
  &__info
    h 100%
    overflow hidden
  &__item
    display flex
    overflow hidden
    justify-content center
    align-items center
    position relative
    w 100%
    @media (max-width: 767px)
      h 220px !important
    @media screen and (max-width: 992px) and (min-width: 544px) and (orientation: portrait)
      w 50% !important
      h 33.33% !important
    @media (max-width: 992px) and (min-height: 400px) and (orientation: landscape)
      w 50% !important
      h 50% !important
    @media (max-width: 992px) and (max-height: 400px) and (orientation: landscape)
      w 50% !important
      h 100% !important
    @media (max-width: 1200px) and (min-width: 993px)
      w 33.33% !important
      h 33.33% !important
    +media(from('xl'))
      w 33.33% !important
      h 33.33%
    &--pointer
      cursor pointer
      img
        transition 1s all ease
      &:hover
        +media(from('xl'))
          img
            transition 1s all ease
            transform translate(-50%,-50%) scale(1.2)
    &--content 
      &:hover
        .gallery
          &__content
            +media(from('md'))
              h 100%
              transition .3s height ease
          &__heading,
          h2
            h 66px
            +media(from('md'))
              h 78px
            +media(from('xl'))
              h 62px
            +media(from('xl2'))
              h 78px
            +media(from('fhd'))
              h 78px
    &--main
      &:after
        content ''
        position absolute
        w 100%
        h 100%
        top 0
        left 0
        bg rgba(0,0,0,.8)
    img
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
      &.is-absolute
        max-width 100%
        max-height 100%
        height 100%
        width 100%
      &.is-fit
        max-h 500px
        object-fit cover
  &__wrapper
    display flex
    overflow auto
    flex-wrap wrap
  &__heading
    p
      ma 0
  &__heading,
  h2
    color #fff
    ma 0
    h 66px
    cursor inherit
    overflow hidden
    letter-spacing 0.77px
    fs 16px
    mb 10px
    +media(from('md'))
      fs 22px
      lh 26px
      h 78px
    +media(from('xl'))
      h 100%
      mb 10px
      lh 20px
      fs 16px
    +media(from('fhd'))
      fs 22px
      lh 26px
      mb 40px
  &__date
    display none
    +media(from('xl'))
      display block
      fs 10px
      color #fff
      letter-spacing 0.77px
      position absolute
      top 7px
      right 15px
  &__title
    color #fff
    z-index 2
    ma 0
    ta center
    fs 22px
    +media(from('md'))
      fs 30px
    +media(from('lg'))
      fs 48px
  &__text
    font-size 14px
    cursor inherit
    fw 300
    color #fff
    letter-spacing 0.42px
    +media(from('xl2'))
      font-size 16px
    p
      cursor inherit