.download
  color #fff
  position relative
  mr 20px
  fs 16px
  +media(from('xl'))
    fs 22px
    color $blue
  +media(from('fhd'))
    mr 50px
  a
    color inherit
  &:focus
    color $blue
  &:hover
    .download
      &__content
        +media(from('xl'))
          opacity 1
          visibility visible
  &__content
    bg-color $blue
    position absolute
    fs 12px
    opacity 0
    left 50%
    transform translateX(-50%)
    top 55px
    visibility hidden
    transition .3s all ease
    pa 10px
    box-sizing border-box
    color #fff
    fs 13px
    z-index 3
    cursor text
    text-align center
    w 150px
    +media(from('xl'))
      w 190px
    &:after
      content ''
      position absolute
      top -14px
      left 50%
      transform translateX(-50%)
      border 7px solid transparent
      border-bottom-color $blue 
    p
      ma 0
