.socials
  display flex
  justify-content space-between
  mt 20px
  ml auto
  mr auto
  max-width 190px
  +media(from('md'))
    mt 100px
  +media(from('xl'))
    ml 0
    mt 20px
    max-width 100%
  +media(from('fhd'))
    max-width 310px
    mt 70px
  &__item
    &:hover
      img
        transform rotate(360deg)
        transition .3s all ease
      img
        transition .3s all ease