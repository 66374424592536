.nav
  w calc(100% - 200px)
  display flex
  align-items center
.menu
  display none
  overflow hidden
  w 100%
  top 37px
  height calc(100% - 37px)
  &--mobile
    +media(from('xl'))
      display none
  &--desktop
    display none
    +media(from('xl'))
      display flex
      h 42px
  +media(from('md'))
    top 61px
    height calc(100% - 61px)
  +media(from('xl'))
    top 78px
    overflow visible
    justify-content space-between
  &.is-active
    display flex
    flex-direction column
    position fixed
    z-index 2
    width 100%
    background #000
    left 0
    overflow auto
    pa 16px 50px
  &__item
    pa 7px 0
    position relative
    transition .3s all ease
    border 1px solid transparent
    border-radius 5px
    display inline-flex
    align-items center
    mt 20px
    &:first-child
      mt 35px
      +media(from('xl'))
        mt 0
    +media(from('xl'))
      mt 0
    &:hover
      +media(from('xl'))
        border-color $blue
      .submenu
        +media(from('xl'))
          visibility visible
          transition .3s all ease
          opacity 1
      .menu
        &__link
          +media(from('xl'))
            color $blue
    &:before
      +media(from('xl'))
        content ''
        h 0px
        bg-color $blue
        h 2px
        position absolute
        top 0
        left 50%
        transform translateX(-50%)
        opacity 0
        w calc(100% - 40px)
      +media(from('fhd'))
        w calc(100% - 48px)
    &:hover,
    &.is-active
      .menu
        &__link
          +media(from('xl'))
            color $blue
    &.is-active
      &:before
        opacity 1
      &:hover
        &:before
          opacity 0
    &.has-childs
      +media(from('xl'))
        border-radius 0
        border-top-left-radius 5px
        border-top-right-radius 5px
      &:hover
        &:before,
        &:after
          +media(from('xl'))
            content ''
            position absolute
            bg-color #000
            h 1px
            w 24px
            transform none
            opacity 1
            bottom -1px
            top auto
            z-index 1
        &:before
          +media(from('xl'))
            left 0
        &:after
          +media(from('xl'))
            right 0
  &__link-wrapper
    display flex
    align-items center
  &__link
    fw bold
    text-decoration none !important
    color #fff
    transition .3s all ease
    font-family "Helvetica Neue",Helvetica,Arial,sans-serif
    font-size 20px
    line-height 26px
    text-transform uppercase
    +media(from('xl'))
      text-transform none
    &--highlight
      color $blue
      position relative
      &:after
        content ''
        position absolute
        left 0
        bottom -9px
        w 100%
        h 1px
        bg-color $blue
    &--fw-normal
      fw normal
    +media(from('xl'))
      fw 400
      pa 10px
      fs 18px
    +media(from('xl2'))
      pa 10px 20px
    +media(from('fhd'))
      pa 10px 24px
      fs 21px
  &__toggler
    display inline-flex
    align-items center
    ml 14px
    +media(from('md'))
      ml 20px
    +media(from('xl'))
      display none
    &--prev
      ml 0
      mr 14px
      +media(from('md'))
        ml 0
        mr 20px
      +media(from('sm'))
        mr 50px