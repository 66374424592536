@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.eot')
	src: url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), 
			 url('./../fonts/svgfont.woff') format('woff'),
			 url('./../fonts/svgfont.woff2') format('woff2'),
			 url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal

$icon-download= "\EA01"
$icon-category-arrow= "\EA02"


$icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend $icon
	display: inline-block

.icon-download:before
	content: $icon-download
.icon-category-arrow:before
	content: $icon-category-arrow
