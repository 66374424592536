.categories
  w 100%
  h calc(100vh - 93px)
  overflow auto
  ma 0
  w 100%
  pa 0
  +media(from('md'))
    pa 0
    w 100%
    h calc(100vh - 162px)
  +media(from('xl'))
    ml auto
    mr 0
    w 100%
    h 100%
    pa 40px 0
  +media(from('xl2'))
    pa 72px 0
  &__wrapper
    w 100%
    overflow auto
    h 100%
    display flex
    flex-wrap wrap
    +media(from('xl'))
      display block
    &::-webkit-scrollbar
      +media(from('xl'))
        w 12px
    &::-webkit-scrollbar-track
      +media(from('xl'))
        border-radius 10px 
    &::-webkit-scrollbar-thumb
      +media(from('xl'))
        background #343434
        border-radius 10px
  &__item
    h 33.33%
    bg-size cover
    bg-repeat no-repeat
    display flex
    align-items flex-end
    w 100%
    +media(from('sm'))
      w 50%
    @media (max-width: 992px) and (min-height: 400px) and (orientation: landscape)
      w 50% !important
      h 50% !important
    @media (max-width: 992px) and (max-height: 400px) and (orientation: landscape)
      w 50% !important
      h 100% !important
    @media (max-width: 1199px) and (orientation:landscape)
      h 50%
      w 50%
    +media(from('xl'))
      bg-image none !important
      mb 12px
      w 28vw
      h auto
      ml auto
      mr 0
      transition .3s all ease
    &:hover
      +media(from('xl'))
        w 29vw
    &:last-child
      mb 0
  &__link
    color #fff
    bg rgba(0,0,0,.8)
    transition .3s all ease
    fs 18px
    w 100%
    ta center
    pa 8px 12px
    pa 8px 16px
    +media(from('md'))
      fs 22px
    +media(from('xl'))
      display flex
      fs 16px
      justify-content space-between
      align-items center
      ta left
    +media(from('xl2'))
      pa 12px
      fs 18px
    +media(from('fhd'))
      pa 15px 40px
      fs 22px
    &:hover
      text-decoration none
      color #26aded
      +media(from('xl'))
        bg #fff
        transition .3s all ease
        pl 20px
      +media(from('fhd'))
        pl 48px
      .categories
        &__icon
          img
            &:first-child
              display none
            &:last-child
              display block
  &__icon
    display none
    +media(from('xl'))
      display block
      h 30px
    +media(from('fhd'))
      h 45px
    img
      h 100%
      &:last-child
        display none
