.scroll-down
  display none
  +media(from('xl'))
    cursor pointer
    display block
    border 3px solid #fff
    border-radius 50px
    w 44px
    h 90px
    display flex
    position absolute
    bottom 20px
    left 50%
    transform translateX(-50%)
    z-index 2
  &__image
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    animation scrollDown 1s infinite ease-out

@keyframes scrollDown
  0%
    transform translate(-50%, calc(-50% - 5px))
  25%
    transform translate(-50%, -50%)
  50%
    transform translate(-50%, calc(-50% + 5px))
  75%
    transform translate(-50%, -50%)
  100%
    transform translate(-50%, calc(-50% - 5px))