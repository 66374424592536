.header
  h 37px
  box-sizing border-box
  position fixed
  z-index 999
  bg-color #000
  w 100%
  color #fff
  fs 16px
  +media(from('md'))
    h 61px
  +media(from('xl'))
    h 78px
  &__wrapper
    display flex
    align-items center
    h 100%
    justify-content flex-end
    pa 0 2.5vw
    +media(from('xl'))
      justify-content space-between
  &__actions
    display flex
    align-items center