body.uct-tower-page
  background-attachment fixed
.tower-content
  mb 70px
  ta justify
  color #fff
  letter-spacing 2px
  font-weight 300
  fs 16px
  +media(from('xl'))
    pr 0
  +media(from('xl2'))
    fs 18px
  +media(from('fhd'))
    fs 20px
  h1, h2
    box-sizing border-box
    ma 0 auto
    mb 20px
    fs 16px
    lh 1.4
    color $blue
    +media(from('md'))
      mb 30px
    +media(from('xl'))
      mb 40px
      fs 20px
    +media(from('xl2'))
      fs 26px
.pointer-none
  pointer-events none
.uct-tower-page
  &::-webkit-scrollbar
    +media(from('xl'))
      w 15px
  &::-webkit-scrollbar-track
    border-radius 10px 
  &::-webkit-scrollbar-thumb
    background #343434
    border-radius 10px
.fancybox-wrap
  w 95% !important
  left 50% !important
  h calc(100% - 30px) !important
  transform translateX(-50%)
  +media(from('xl'))
    w 85% !important
.fancybox-inner
  w calc(100% - 30px) !important
.fancybox-skin,
.fancybox-skin div
  h 100% !important
.fancybox-inner
  w 100% !important
.fancybox-inner div:last-child
  display flex
  align-items center
.uct-main-wrapper
  &--text
    pa 0 !important
    height auto !important
    w calc(100% - 30px) !important
    ml auto
    mr auto
    w 100%
    +media(from('xl'))
      w 65% !important
      mr 2.5vw
.swiper-container-vertical>.swiper-wrapper
  flex-direction row !important