.contacts
  mb 10px
  +media(from('md'))
    mb 20px
  +media(from('xl'))
    w 50%
  &__wrapper
    +media(from('xl'))
      max-width 320px
      ma 0 auto
  p
    ma 0
  &__text
    color #fff
    letter-spacing 0.9px
    mt 10px
    pa 0 15px
    ta center
    +media(from('md'))
      fs 22px
      mt 20px
    +media(from('xl'))
      pa 0
      mt 10px
      fs 24px
    +media(from('fhd'))
      fs 30px
      mt 30px
  &__shedule
    fs 12px
    +media(from('xl'))
      fs 18px
  &__logo
    ta center
    h 70px
    +media(from('md'))
      h 100px
    +media(from('fhd'))
      h 119px
    img
      h 100%
  a
    color inherit
    fs inherit
    transition .3s color ease
    &:hover
      color $blue
      transition .3s color ease