.submenu
  bg-color #000
  position fixed
  left 200%
  z-index 3
  pa 56px 50px 16px 50px
  transition .5s left ease
  top 37px
  height calc(100% - 37px)
  w 100%
  box-sizing border-box
  overflow auto
  +media(from('md'))
    top 61px
    height calc(100% - 61px)
  &.is-active
    left 0
    transition .5s left ease
  +media(from('xl'))
    border 1px solid $blue
    left 0
    position absolute
    h auto
    pa 10px 24px
    display block
    border-radius 5px
    visibility hidden
    transition .3s all ease
    w 294px
    top 100%
    opacity 0
    border-top-left-radius 0
    left -1px
    position absolute
  &__category
    color #fff
    fw 600
    mb 10px
  &__wrapper
    display flex
    flex-direction column
  &__list
    ma 0
    display flex
    flex-direction column
    mb 10px
    +media(from('xl'))
      list-style-type disc
      pl 20px
  &__link
    color #fff
    fs 16px
  &__item
    pa 5px 0
  &__category,
  &__link
    &:hover
      color $blue