:root {
  --define: ms (Math.pow(1.618, value - 3))rem;
  --define: sp (0.25 * Math.pow(2, value - 1))rem;
  --define: ty (value > 0 && value < 10 ? [0.75, 0.875, 1, 1.25, 1.5, 2.25, 3, 5, 6][Math.round(value)|0-1] : Math.pow(1.33, value - 3))rem;
  --define: vr (1 * 1.6 * value)rem;
}
@alias {
  d: display;
  pos: position;
  bg: background;
  bg-attachment: background-attachment;
  bg-blend-mode: background-blend-mode;
  bg-clip: background-clip;
  bg-color: background-color;
  bg-image: background-image;
  bg-origin: background-origin;
  bg-position: background-position;
  bg-repeat: background-repeat;
  bg-size: background-size;
  br: border;
  br-block-end: border-block-end;
  br-block-end-color: border-block-end-color;
  br-block-end-style: border-block-end-style;
  br-block-end-width: border-block-end-width;
  br-block-start: border-block-start;
  br-block-start-color: border-block-start-color;
  br-block-start-style: border-block-start-style;
  br-block-start-width: border-block-start-width;
  br-b: border-bottom;
  br-bottom: border-bottom;
  br-bottom-color: border-bottom-color;
  br-bottom-left-radius: border-bottom-left-radius;
  br-bottom-right-radius: border-bottom-right-radius;
  br-bottom-style: border-bottom-style;
  br-bottom-width: border-bottom-width;
  br-collapse: border-collapse;
  br-color: border-color;
  br-image: border-image;
  br-image-outset: border-image-outset;
  br-image-repeat: border-image-repeat;
  br-image-slice: border-image-slice;
  br-image-source: border-image-source;
  br-image-width: border-image-width;
  br-inline-end: border-inline-end;
  br-inline-end-color: border-inline-end-color;
  br-inline-end-style: border-inline-end-style;
  br-inline-end-width: border-inline-end-width;
  br-inline-start: border-inline-start;
  br-inline-start-color: border-inline-start-color;
  br-inline-start-style: border-inline-start-style;
  br-inline-start-width: border-inline-start-width;
  br-l: border-left;
  br-left: border-left;
  br-left-color: border-left-color;
  br-left-style: border-left-style;
  br-left-width: border-left-width;
  br-radius: border-radius;
  br-r: border-right;
  br-right: border-right;
  br-right-color: border-right-color;
  br-right-style: border-right-style;
  br-right-width: border-right-width;
  br-spacing: border-spacing;
  br-style: border-style;
  br-t: border-top;
  br-top: border-top;
  br-top-color: border-top-color;
  br-top-left-radius: border-top-left-radius;
  br-top-right-radius: border-top-right-radius;
  br-top-style: border-top-style;
  br-top-width: border-top-width;
  br-width: border-width;
  fs: font-size;
  fw: font-weight;
  fh: line-height;
  fa: text-align;
  fd: text-decoration;
  ft: text-transform;
  ta: text-align;
  td: text-decoration;
  lh: line-height;
  h: height;
  max-h: max-height;
  min-h: min-height;
  w: width;
  max-w: max-width;
  min-w: min-width;
  ma: margin;
  mt: margin-top;
  mr: margin-right;
  mb: margin-bottom;
  ml: margin-left;
  pa: padding;
  pt: padding-top;
  pr: padding-right;
  pb: padding-bottom;
  pl: padding-left;
  va: vertical-align;
}
@font-face {
  font-family: "svgfont";
  src: url("./../fonts/svgfont.eot");
  src: url("./../fonts/svgfont.eot?#iefix") format('embedded-opentype'), url("./../fonts/svgfont.woff") format('woff'), url("./../fonts/svgfont.woff2") format('woff2'), url("./../fonts/svgfont.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
.icon,
[class^="icon-"],
[class*=" icon-"] {
  font-family: "svgfont";
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon,
[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
}
.icon-download:before {
  content: "\EA01";
}
.icon-category-arrow:before {
  content: "\EA02";
}
.download {
  color: #fff;
  position: relative;
  mr: 20px;
  fs: 16px;
}
@media only screen and (min-width: 1200px) {
  .download {
    fs: 22px;
    color: #46a0ff;
  }
}
@media only screen and (min-width: 1680px) {
  .download {
    mr: 50px;
  }
}
.download a {
  color: inherit;
}
.download:focus {
  color: #46a0ff;
}
@media only screen and (min-width: 1200px) {
  .download:hover .download__content {
    opacity: 1;
    visibility: visible;
  }
}
.download__content {
  bg-color: #46a0ff;
  position: absolute;
  fs: 12px;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%);
  top: 55px;
  visibility: hidden;
  transition: 0.3s all ease;
  pa: 10px;
  box-sizing: border-box;
  color: #fff;
  fs: 13px;
  z-index: 3;
  cursor: text;
  text-align: center;
  w: 150px;
}
@media only screen and (min-width: 1200px) {
  .download__content {
    w: 190px;
  }
}
.download__content:after {
  content: '';
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
  border: 7px solid transparent;
  border-bottom-color: #46a0ff;
}
.download__content p {
  ma: 0;
}
.header {
  h: 37px;
  box-sizing: border-box;
  position: fixed;
  z-index: 999;
  bg-color: #000;
  w: 100%;
  color: #fff;
  fs: 16px;
}
@media only screen and (min-width: 768px) {
  .header {
    h: 61px;
  }
}
@media only screen and (min-width: 1200px) {
  .header {
    h: 78px;
  }
}
.header__wrapper {
  display: flex;
  align-items: center;
  h: 100%;
  justify-content: flex-end;
  pa: 0 2.5vw;
}
@media only screen and (min-width: 1200px) {
  .header__wrapper {
    justify-content: space-between;
  }
}
.header__actions {
  display: flex;
  align-items: center;
}
.logo {
  h: 52px;
  top: 10px;
  position: absolute;
  border: 6px solid #000;
  bg-color: #000;
  border-radius: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  display: flex;
}
@media only screen and (min-width: 768px) {
  .logo {
    h: 110px;
    border: 14px solid #000;
    top: 4px;
    border-radius: 40px;
  }
}
@media only screen and (min-width: 1200px) {
  .logo {
    border: 0;
    border-radius: 0;
    h: 100%;
    max-height: 50px;
    position: relative;
    left: 0;
    transform: none;
  }
}
.logo img {
  h: 100%;
}
.nav {
  w: calc(100% - 200px);
  display: flex;
  align-items: center;
}
.menu {
  display: none;
  overflow: hidden;
  w: 100%;
  top: 37px;
  height: calc(100% - 37px);
}
@media only screen and (min-width: 1200px) {
  .menu--mobile {
    display: none;
  }
}
.menu--desktop {
  display: none;
}
@media only screen and (min-width: 1200px) {
  .menu--desktop {
    display: flex;
    h: 42px;
  }
}
@media only screen and (min-width: 768px) {
  .menu {
    top: 61px;
    height: calc(100% - 61px);
  }
}
@media only screen and (min-width: 1200px) {
  .menu {
    top: 78px;
    overflow: visible;
    justify-content: space-between;
  }
}
.menu.is-active {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  width: 100%;
  background: #000;
  left: 0;
  overflow: auto;
  pa: 16px 50px;
}
.menu__item {
  pa: 7px 0;
  position: relative;
  transition: 0.3s all ease;
  border: 1px solid transparent;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  mt: 20px;
}
.menu__item:first-child {
  mt: 35px;
}
@media only screen and (min-width: 1200px) {
  .menu__item:first-child {
    mt: 0;
  }
}
@media only screen and (min-width: 1200px) {
  .menu__item {
    mt: 0;
  }
}
@media only screen and (min-width: 1200px) {
  .menu__item:hover {
    border-color: #46a0ff;
  }
}
@media only screen and (min-width: 1200px) {
  .menu__item:hover .submenu {
    visibility: visible;
    transition: 0.3s all ease;
    opacity: 1;
  }
}
@media only screen and (min-width: 1200px) {
  .menu__item:hover .menu__link {
    color: #46a0ff;
  }
}
@media only screen and (min-width: 1200px) {
  .menu__item:before {
    content: '';
    h: 0px;
    bg-color: #46a0ff;
    h: 2px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    w: calc(100% - 40px);
  }
}
@media only screen and (min-width: 1680px) {
  .menu__item:before {
    w: calc(100% - 48px);
  }
}
@media only screen and (min-width: 1200px) {
  .menu__item:hover .menu__link,
  .menu__item.is-active .menu__link {
    color: #46a0ff;
  }
}
.menu__item.is-active:before {
  opacity: 1;
}
.menu__item.is-active:hover:before {
  opacity: 0;
}
@media only screen and (min-width: 1200px) {
  .menu__item.has-childs {
    border-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
@media only screen and (min-width: 1200px) {
  .menu__item.has-childs:hover:before,
  .menu__item.has-childs:hover:after {
    content: '';
    position: absolute;
    bg-color: #000;
    h: 1px;
    w: 24px;
    transform: none;
    opacity: 1;
    bottom: -1px;
    top: auto;
    z-index: 1;
  }
}
@media only screen and (min-width: 1200px) {
  .menu__item.has-childs:hover:before {
    left: 0;
  }
}
@media only screen and (min-width: 1200px) {
  .menu__item.has-childs:hover:after {
    right: 0;
  }
}
.menu__link-wrapper {
  display: flex;
  align-items: center;
}
.menu__link {
  fw: bold;
  text-decoration: none !important;
  color: #fff;
  transition: 0.3s all ease;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
}
@media only screen and (min-width: 1200px) {
  .menu__link {
    text-transform: none;
  }
}
.menu__link--highlight {
  color: #46a0ff;
  position: relative;
}
.menu__link--highlight:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -9px;
  w: 100%;
  h: 1px;
  bg-color: #46a0ff;
}
.menu__link--fw-normal {
  fw: normal;
}
@media only screen and (min-width: 1200px) {
  .menu__link {
    fw: 400;
    pa: 10px;
    fs: 18px;
  }
}
@media only screen and (min-width: 1440px) {
  .menu__link {
    pa: 10px 20px;
  }
}
@media only screen and (min-width: 1680px) {
  .menu__link {
    pa: 10px 24px;
    fs: 21px;
  }
}
.menu__toggler {
  display: inline-flex;
  align-items: center;
  ml: 14px;
}
@media only screen and (min-width: 768px) {
  .menu__toggler {
    ml: 20px;
  }
}
@media only screen and (min-width: 1200px) {
  .menu__toggler {
    display: none;
  }
}
.menu__toggler--prev {
  ml: 0;
  mr: 14px;
}
@media only screen and (min-width: 768px) {
  .menu__toggler--prev {
    ml: 0;
    mr: 20px;
  }
}
@media only screen and (min-width: 544px) {
  .menu__toggler--prev {
    mr: 50px;
  }
}
.submenu {
  bg-color: #000;
  position: fixed;
  left: 200%;
  z-index: 3;
  pa: 56px 50px 16px 50px;
  transition: 0.5s left ease;
  top: 37px;
  height: calc(100% - 37px);
  w: 100%;
  box-sizing: border-box;
  overflow: auto;
}
@media only screen and (min-width: 768px) {
  .submenu {
    top: 61px;
    height: calc(100% - 61px);
  }
}
.submenu.is-active {
  left: 0;
  transition: 0.5s left ease;
}
@media only screen and (min-width: 1200px) {
  .submenu {
    border: 1px solid #46a0ff;
    left: 0;
    position: absolute;
    h: auto;
    pa: 10px 24px;
    display: block;
    border-radius: 5px;
    visibility: hidden;
    transition: 0.3s all ease;
    w: 294px;
    top: 100%;
    opacity: 0;
    border-top-left-radius: 0;
    left: -1px;
    position: absolute;
  }
}
.submenu__category {
  color: #fff;
  fw: 600;
  mb: 10px;
}
.submenu__wrapper {
  display: flex;
  flex-direction: column;
}
.submenu__list {
  ma: 0;
  display: flex;
  flex-direction: column;
  mb: 10px;
}
@media only screen and (min-width: 1200px) {
  .submenu__list {
    list-style-type: disc;
    pl: 20px;
  }
}
.submenu__link {
  color: #fff;
  fs: 16px;
}
.submenu__item {
  pa: 5px 0;
}
.submenu__category:hover,
.submenu__link:hover {
  color: #46a0ff;
}
.play-button {
  display: none;
  position: relative;
}
@media only screen and (min-width: 1200px) {
  .play-button {
    z-index: 999;
    cursor: pointer;
    h: 22%;
    w: 11%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s all ease;
  }
  .play-button:hover {
    transition: 1s all ease;
    transform: translate(-50%, -50%) scale(1.1);
  }
}
.play-button img {
  h: 100%;
}
.lang-switcher {
  position: relative;
  z-index: 2;
}
.lang-switcher__content {
  display: none;
  position: absolute;
}
.lang-switcher__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.lang-switcher__item {
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  bg-color: #000;
  fw: 900;
  pa: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid #46a0ff;
  border-right: 2px solid #46a0ff;
  transition: 0.3s all ease;
  w: 26px;
  h: 26px;
  fs: 11px;
}
@media only screen and (min-width: 1200px) {
  .lang-switcher__item {
    w: 36px;
    h: 36px;
    color: #46a0ff;
  }
}
.lang-switcher__item:last-child {
  border-bottom: 2px solid #46a0ff;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}
.lang-switcher__item.is-current {
  border: 2px solid #000;
  border-radius: 50%;
}
@media only screen and (min-width: 1200px) {
  .lang-switcher__item.is-current {
    border: 2px solid #46a0ff;
    position: relative;
  }
}
@media only screen and (min-width: 1200px) {
  .lang-switcher__item.is-current:after {
    content: '';
    position: absolute;
    bottom: -16px;
    border: 6px solid transparent;
    border-top-color: #46a0ff;
    left: 50%;
    transform: translateX(-50%);
  }
}
.lang-switcher__item.is-active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
  transition: 0.3s all ease;
}
@media only screen and (min-width: 1200px) {
  .lang-switcher__item.is-active {
    border-bottom: 0;
  }
}
.lang-switcher__item.is-active.is-current {
  border-color: #46a0ff;
}
.hamburger {
  position: absolute;
  h: 10px;
  cursor: pointer;
  z-index: 100;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  w: 18px;
}
@media only screen and (min-width: 768px) {
  .hamburger {
    w: 37px;
    h: 22px;
  }
}
@media only screen and (min-width: 1200px) {
  .hamburger {
    display: none;
  }
}
.hamburger.is-active .hamburger__line {
  transition: 0.5s all ease;
}
.hamburger.is-active .hamburger__line:nth-child(2) {
  w: 0;
}
.hamburger.is-active .hamburger__line:first-child,
.hamburger.is-active .hamburger__line:last-child {
  top: 50%;
}
.hamburger.is-active .hamburger__line:first-child {
  transform: translate(-50%, -50%) rotate(45deg);
}
.hamburger.is-active .hamburger__line:last-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.hamburger__line {
  position: absolute;
  left: 50%;
  w: 100%;
  transition: 0.5s all ease;
  transform: translateX(-50%);
  bg-color: #fff;
  h: 2px;
}
@media only screen and (min-width: 1200px) {
  .hamburger__line {
    h: 3px;
  }
}
@media only screen and (min-width: 1200px) {
  .hamburger__line {
    bg-color: #46a0ff;
  }
}
.hamburger__line:first-child {
  top: 0;
}
.hamburger__line:nth-child(2) {
  top: 4px;
}
@media only screen and (min-width: 768px) {
  .hamburger__line:nth-child(2) {
    top: 9px;
  }
}
.hamburger__line:last-child {
  top: 8px;
}
@media only screen and (min-width: 768px) {
  .hamburger__line:last-child {
    top: 19px;
  }
}
.main-content {
  width: 100%;
  height: calc(100% - 37px);
  z-index: 200;
  position: absolute;
  left: 0px;
  top: 37px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ta: left;
}
@media only screen and (min-width: 768px) {
  .main-content {
    top: 61px;
    height: calc(100% - 61px);
  }
}
@media only screen and (min-width: 1200px) {
  .main-content {
    top: 78px;
    height: calc(100% - 78px);
  }
}
.main-content--bg {
  bg-size: cover;
  bg-repeat: no-repeat;
}
.main-content__title {
  color: #fff;
  position: absolute;
  z-index: 2;
  w: 188px;
  pa: 7px 20px;
  h: auto;
  box-sizing: border-box;
  fs: 16px;
  line-height: 19px;
  bg: rgba(0,0,0,0.7);
  bottom: 191px;
  ma: 0;
  ta: left;
  letter-spacing: 2px;
}
@media only screen and (min-width: 768px) {
  .main-content__title {
    lh: 33px;
    bottom: 170px;
    pa: 35px 39px 20px 39px;
    bg: #000;
    w: 571px;
    fs: 28px;
  }
}
@media only screen and (min-width: 1200px) {
  .main-content__title {
    display: flex;
    justify-content: center;
    align-items: center;
    pa: 0 20px;
    ta: center;
    h: 10vh;
    ml: 2.5vw;
    w: 30vw;
    position: absolute;
    top: 0;
    bg: rgba(0,0,0,0.8);
    fs: 22px;
  }
}
@media only screen and (min-width: 1680px) {
  .main-content__title {
    pa: 0 30px;
    fs: 28px;
  }
}
.main-content__title.is-full {
  display: flex;
  bottom: 70%;
}
.main-content__title--template.is-full {
  w: 100%;
}
@media only screen and (min-width: 768px) {
  .main-content__title--template.is-full {
    w: 100%;
    pa: 15px 39px 15px;
    bg: rgba(0,0,0,0.7);
  }
}
@media only screen and (min-width: 1200px) {
  .main-content__title--template.is-full {
    w: 30vw;
    pa: 0 20px;
  }
}
.main-content__title--full {
  position: relative;
  bottom: 0;
  width: 100%;
  pa: 0;
  mb: 25px;
  mt: 25px;
  display: block;
}
@media only screen and (min-width: 768px) {
  .main-content__title--full {
    bottom: 0;
    pa: 90px 0 20px 0;
    w: 100%;
  }
}
@media only screen and (min-width: 1200px) {
  .main-content__title--full {
    display: none;
  }
}
@media only screen and (min-width: 1200px) {
  .main-content__title--gallery {
    display: none;
  }
}
.main-content__title--article {
  position: relative;
}
@media only screen and (min-width: 1200px) {
  .main-content__title--article {
    display: none;
  }
}
.main-content__title--contacts,
.main-content__title--full-w {
  bg-color: #000;
  top: 0;
  w: 100%;
  bottom: auto;
  pt: 30px;
  ta: center;
}
@media only screen and (min-width: 768px) {
  .main-content__title--contacts,
  .main-content__title--full-w {
    top: 0;
    pt: 50px;
    ta: center;
    w: 100%;
    bottom: auto;
  }
}
@media only screen and (min-width: 1200px) {
  .main-content__title--contacts,
  .main-content__title--full-w {
    bg-color: #000;
  }
}
@media only screen and (min-width: 1200px) {
  .main-content__title--full-w {
    bg: rgba(0,0,0,0.8);
  }
}
.main-content__back {
  position: absolute;
  right: 20px;
  z-index: 2;
  cursor: pointer;
  top: 29px;
  w: 16px;
  h: 16px;
}
@media only screen and (min-width: 768px) {
  .main-content__back {
    top: 54px;
    w: 25px;
    h: 25px;
  }
}
@media only screen and (min-width: 1200px) {
  .main-content__back {
    right: 40px;
    top: 5px;
    w: 31px;
    h: 31px;
  }
}
.main-content__back img {
  w: 100%;
}
.main-content__image {
  bg-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  w: 100%;
  h: 100%;
  top: 0;
  left: 0;
}
.main-content__info {
  pa: 20px;
  pb: 30px;
  position: absolute;
  bottom: 0;
  z-index: 2;
  color: #fff;
  box-sizing: border-box;
  h: 191px;
  bg: #000;
}
@media only screen and (min-width: 768px) {
  .main-content__info {
    w: 571px;
    pa: 20px 39px 29px 39px;
    h: 170px;
  }
}
@media only screen and (min-width: 1200px) {
  .main-content__info {
    pa: 10px 20px;
    bg: rgba(0,0,0,0.8);
    ma: 0 2.5vw;
    width: 30vw;
    h: auto;
  }
}
@media only screen and (min-width: 1680px) {
  .main-content__info {
    h: auto;
    pa: 20px 30px;
  }
}
.main-content__info.is-hidden {
  display: none;
}
.main-content__info.is-full {
  bg: #000;
  h: 70%;
}
@media only screen and (min-width: 768px) {
  .main-content__info.is-full {
    box-sizing: border-box;
    w: 100%;
  }
}
@media only screen and (min-width: 1200px) {
  .main-content__info.is-full {
    max-width: 100%;
    left: auto;
    pa: 20px 2.5vw;
    w: 67.5vw;
    h: calc(90vh - 78px);
    right: -2.5vw;
  }
}
@media only screen and (min-width: 1680px) {
  .main-content__info.is-full {
    pa: 44px 2.5vw;
  }
}
.main-content__info.is-full .main-content__description--full {
  overflow: auto;
  h: 100%;
  display: block;
}
.main-content__info.is-full .main-content__description--common {
  display: none;
}
@media only screen and (min-width: 1200px) {
  .main-content__info.is-full .main-content__wrapper {
    pr: 44px;
  }
}
@media only screen and (min-width: 768px) {
  .main-content__info.is-full .main-content__title--full {
    display: block;
  }
}
@media only screen and (min-width: 1200px) {
  .main-content__info.is-full .main-content__title--full {
    display: none;
  }
}
.main-content__info.is-full .main-content__more {
  display: none;
}
.main-content__info.is-full .main-content h2,
.main-content__info.is-full .main-content h3,
.main-content__info.is-full .main-content__heading {
  overflow: visible;
  h: auto;
}
.main-content__info--dark {
  bg-color: #000;
}
.main-content__info--contacts {
  w: 100%;
  h: calc(100vh - 93px);
  overflow: auto;
  ma: 0;
  w: 100%;
  pa: 0;
}
@media only screen and (min-width: 768px) {
  .main-content__info--contacts {
    pa: 0;
    w: 100%;
    h: calc(100vh - 162px);
  }
}
@media only screen and (min-width: 1200px) {
  .main-content__info--contacts {
    right: -2.5vw;
    bg-color: #000;
    display: flex;
    h: calc(100vh - 10vh - 78px);
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    w: 67.5vw;
  }
}
@media only screen and (min-width: 1440px) {
  .main-content__info--contacts {
    h: calc(100vh - 10vh - 78px);
  }
}
@media only screen and (min-width: 1680px) {
  .main-content__info--contacts {
    h: calc(100vh - 10vh - 78px);
  }
}
.main-content__info--contacts::-webkit-scrollbar {
  w: 4px;
}
@media only screen and (min-width: 1200px) {
  .main-content__info--contacts::-webkit-scrollbar {
    w: 12px;
  }
}
.main-content__info--contacts::-webkit-scrollbar-track {
  border-radius: 10px;
}
.main-content__info--contacts::-webkit-scrollbar-thumb {
  background: #343434;
  border-radius: 10px;
}
.main-content h2,
.main-content h3,
.main-content__heading,
.main-content__more {
  color: #46a0ff;
}
.main-content h2,
.main-content h3,
.main-content__heading {
  ma: 0;
  mb: 10px;
  fs: 16px;
  lh: 1.4;
}
@media only screen and (min-width: 1200px) {
  .main-content h2,
  .main-content h3,
  .main-content__heading {
    fs: 20px;
  }
}
@media only screen and (min-width: 1440px) {
  .main-content h2,
  .main-content h3,
  .main-content__heading {
    fs: 26px;
  }
}
@media only screen and (min-width: 1680px) {
  .main-content h2,
  .main-content h3,
  .main-content__heading {
    mb: 30px;
  }
}
.main-content__heading--white {
  color: #fff;
}
.main-content__heading--contacts {
  display: none;
}
@media only screen and (min-width: 1200px) {
  .main-content__heading--contacts {
    display: block;
  }
}
.main-content__heading--hidden {
  lh: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media only screen and (min-width: 1200px) {
  .main-content__heading--hidden {
    max-h: 68px;
  }
}
.main-content__smart-info {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media only screen and (min-width: 1200px) {
  .main-content__smart-info {
    max-h: 50px;
  }
}
@media only screen and (min-width: 1680px) {
  .main-content__smart-info {
    max-h: 57px;
  }
}
@media only screen and (min-width: 1200px) {
  .main-content__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.main-content__map {
  w: 100%;
  mb: 20px;
  mt: 20px;
  h: 280px;
}
@media only screen and (min-width: 768px) {
  .main-content__map {
    h: 300px;
  }
}
@media only screen and (min-width: 1200px) {
  .main-content__map {
    w: 45%;
  }
}
@media only screen and (min-width: 1680px) {
  .main-content__map {
    w: 50%;
    h: 500px;
  }
}
@media (max-width: 992px) and (orientation: landscape) {
  .main-content__map {
    ma: 0 auto 20px auto;
    w: 80%;
  }
}
.main-content__copyright {
  display: none;
  letter-spacing: 0.27px;
  fs: 10px;
  color: #868686;
}
@media only screen and (min-width: 1200px) {
  .main-content__copyright {
    display: block;
  }
}
.main-content__copyright p {
  ma: 0;
}
.main-content__description {
  fs: 16px;
}
@media only screen and (min-width: 1440px) {
  .main-content__description {
    fs: 18px;
  }
}
@media only screen and (min-width: 1680px) {
  .main-content__description {
    fs: 20px;
  }
}
.main-content__description--full {
  h: calc(100% - 52px);
  display: none;
  max-height: 90%;
  overflow: hidden;
}
@media only screen and (min-width: 768px) {
  .main-content__description--full {
    max-height: 58vh;
  }
}
@media only screen and (min-width: 1000px) {
  .main-content__description--full {
    h: 73%;
  }
}
@media only screen and (min-width: 1200px) {
  .main-content__description--full {
    max-height: 85%;
    h: 85%;
  }
}
@media only screen and (min-width: 1440px) {
  .main-content__description--full {
    max-height: 85%;
    h: 85%;
  }
}
@media only screen and (min-width: 1680px) {
  .main-content__description--full {
    max-height: 85%;
    h: 85%;
  }
}
.main-content__description--full::-webkit-scrollbar {
  w: 4px;
}
@media only screen and (min-width: 1200px) {
  .main-content__description--full::-webkit-scrollbar {
    w: 12px;
  }
}
.main-content__description--full::-webkit-scrollbar-track {
  border-radius: 10px;
}
.main-content__description--full::-webkit-scrollbar-thumb {
  background: #343434;
  border-radius: 10px;
}
.main-content__description p {
  ma: 0;
}
.main-content__description p + p {
  mt: 20px;
}
.main-content__more {
  fs: 16px;
  pt: 20px;
  display: inline-block;
}
@media only screen and (min-width: 1000px) {
  .main-content__more {
    pt: 10px;
  }
}
@media only screen and (min-width: 1440px) {
  .main-content__more {
    pt: 30px;
  }
}
@media only screen and (min-width: 1200px) {
  .main-content__more:hover {
    text-decoration: underline;
  }
}
.default-slider {
  h: 100%;
}
.default-slider .owl-wrapper-outer,
.default-slider .owl-wrapper,
.default-slider .owl-item {
  h: 100%;
}
.default-slider .owl-item {
  opacity: 1 !important;
  display: flex;
  align-items: flex-end;
}
.default-slider .owl-item.active {
  opacity: 1 !important;
}
.default-slider__item {
  display: flex;
  align-items: flex-end;
  h: calc(100% - 88px);
}
@media only screen and (min-width: 1200px) {
  .default-slider__item {
    h: calc(100% - 73px);
  }
}
@media only screen and (min-width: 1680px) {
  .default-slider__item {
    h: calc(100% - 132px);
  }
}
.default-slider__item--contacts {
  h: 100%;
}
@media only screen and (min-width: 1200px) {
  .default-slider__item--contacts {
    h: 100%;
  }
}
@media only screen and (min-width: 1680px) {
  .default-slider__item--contacts {
    h: 100%;
  }
}
.default-slider .owl-controls {
  w: 100%;
  position: absolute;
  display: none !important;
}
@media only screen and (min-width: 1200px) {
  .default-slider .owl-controls {
    display: block !important;
  }
}
.default-slider .owl-pagination {
  pr: 80px;
  top: -26px;
  z-index: 2;
  display: flex;
  position: absolute;
  justify-content: flex-end;
  left: auto !important;
  right: 0;
}
@media (max-width: 992px) and (orientation: landscape) {
  .default-slider .owl-pagination {
    left: auto !important;
    right: 0;
  }
}
@media (max-width: 992px) and (orientation: portrait) {
  .default-slider .owl-pagination {
    left: auto !important;
    right: 0;
  }
}
@media only screen and (min-width: 1200px) {
  .default-slider .owl-pagination {
    top: -35px;
  }
}
@media only screen and (min-width: 1680px) {
  .default-slider .owl-pagination {
    top: -70px;
  }
}
.default-slider .owl-buttons {
  position: absolute;
  w: 100%;
  top: -50vh;
}
.default-slider .owl-buttons .owl-prev {
  bg: url("./../img/prev-slide.png") no-repeat;
  left: 5px;
}
.default-slider .owl-buttons .owl-next {
  bg: url("./../img/next-slide.png") no-repeat;
  right: 5px;
}
.default-slider .owl-buttons .owl-prev,
.default-slider .owl-buttons .owl-next {
  border: 0;
  top: 0;
  h: 39px;
  bg-size: contain;
  w: 12px;
}
@media only screen and (min-width: 768px) {
  .default-slider .owl-buttons .owl-prev,
  .default-slider .owl-buttons .owl-next {
    h: 61px;
    w: 19px;
  }
}
@media only screen and (min-width: 1680px) {
  .default-slider .owl-buttons .owl-prev,
  .default-slider .owl-buttons .owl-next {
    h: 100px;
    w: 30px;
  }
}
.default-slider .owl-numbers {
  display: none;
}
.default-slider .owl-page {
  w: 18px;
  h: 18px;
  border: 2px solid #fff;
  position: relative;
  border-radius: 50%;
  box-sizing: border-box;
  ml: 15px;
}
@media only screen and (min-width: 1680px) {
  .default-slider .owl-page {
    w: 22px;
    h: 22px;
  }
}
.default-slider .owl-page:first-child {
  ml: 0;
}
.default-slider .owl-page.active:before {
  content: '';
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bg-color: #46a0ff;
  h: 10px;
  w: 10px;
}
@media only screen and (min-width: 1680px) {
  .default-slider .owl-page.active:before {
    h: 13px;
    w: 13px;
  }
}
.contacts {
  mb: 10px;
}
@media only screen and (min-width: 768px) {
  .contacts {
    mb: 20px;
  }
}
@media only screen and (min-width: 1200px) {
  .contacts {
    w: 50%;
  }
}
@media only screen and (min-width: 1200px) {
  .contacts__wrapper {
    max-width: 320px;
    ma: 0 auto;
  }
}
.contacts p {
  ma: 0;
}
.contacts__text {
  color: #fff;
  letter-spacing: 0.9px;
  mt: 10px;
  pa: 0 15px;
  ta: center;
}
@media only screen and (min-width: 768px) {
  .contacts__text {
    fs: 22px;
    mt: 20px;
  }
}
@media only screen and (min-width: 1200px) {
  .contacts__text {
    pa: 0;
    mt: 10px;
    fs: 24px;
  }
}
@media only screen and (min-width: 1680px) {
  .contacts__text {
    fs: 30px;
    mt: 30px;
  }
}
.contacts__shedule {
  fs: 12px;
}
@media only screen and (min-width: 1200px) {
  .contacts__shedule {
    fs: 18px;
  }
}
.contacts__logo {
  ta: center;
  h: 70px;
}
@media only screen and (min-width: 768px) {
  .contacts__logo {
    h: 100px;
  }
}
@media only screen and (min-width: 1680px) {
  .contacts__logo {
    h: 119px;
  }
}
.contacts__logo img {
  h: 100%;
}
.contacts a {
  color: inherit;
  fs: inherit;
  transition: 0.3s color ease;
}
.contacts a:hover {
  color: #46a0ff;
  transition: 0.3s color ease;
}
.socials {
  display: flex;
  justify-content: space-between;
  mt: 20px;
  ml: auto;
  mr: auto;
  max-width: 190px;
}
@media only screen and (min-width: 768px) {
  .socials {
    mt: 100px;
  }
}
@media only screen and (min-width: 1200px) {
  .socials {
    ml: 0;
    mt: 20px;
    max-width: 100%;
  }
}
@media only screen and (min-width: 1680px) {
  .socials {
    max-width: 310px;
    mt: 70px;
  }
}
.socials__item:hover img {
  transform: rotate(360deg);
  transition: 0.3s all ease;
}
.socials__item:hover img {
  transition: 0.3s all ease;
}
.categories {
  w: 100%;
  h: calc(100vh - 93px);
  overflow: auto;
  ma: 0;
  w: 100%;
  pa: 0;
}
@media only screen and (min-width: 768px) {
  .categories {
    pa: 0;
    w: 100%;
    h: calc(100vh - 162px);
  }
}
@media only screen and (min-width: 1200px) {
  .categories {
    ml: auto;
    mr: 0;
    w: 100%;
    h: 100%;
    pa: 40px 0;
  }
}
@media only screen and (min-width: 1440px) {
  .categories {
    pa: 72px 0;
  }
}
.categories__wrapper {
  w: 100%;
  overflow: auto;
  h: 100%;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 1200px) {
  .categories__wrapper {
    display: block;
  }
}
@media only screen and (min-width: 1200px) {
  .categories__wrapper::-webkit-scrollbar {
    w: 12px;
  }
}
@media only screen and (min-width: 1200px) {
  .categories__wrapper::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}
@media only screen and (min-width: 1200px) {
  .categories__wrapper::-webkit-scrollbar-thumb {
    background: #343434;
    border-radius: 10px;
  }
}
.categories__item {
  h: 33.33%;
  bg-size: cover;
  bg-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  w: 100%;
}
@media only screen and (min-width: 544px) {
  .categories__item {
    w: 50%;
  }
}
@media (max-width: 992px) and (min-height: 400px) and (orientation: landscape) {
  .categories__item {
    w: 50% !important;
    h: 50% !important;
  }
}
@media (max-width: 992px) and (max-height: 400px) and (orientation: landscape) {
  .categories__item {
    w: 50% !important;
    h: 100% !important;
  }
}
@media (max-width: 1199px) and (orientation: landscape) {
  .categories__item {
    h: 50%;
    w: 50%;
  }
}
@media only screen and (min-width: 1200px) {
  .categories__item {
    bg-image: none !important;
    mb: 12px;
    w: 28vw;
    h: auto;
    ml: auto;
    mr: 0;
    transition: 0.3s all ease;
  }
}
@media only screen and (min-width: 1200px) {
  .categories__item:hover {
    w: 29vw;
  }
}
.categories__item:last-child {
  mb: 0;
}
.categories__link {
  color: #fff;
  bg: rgba(0,0,0,0.8);
  transition: 0.3s all ease;
  fs: 18px;
  w: 100%;
  ta: center;
  pa: 8px 12px;
  pa: 8px 16px;
}
@media only screen and (min-width: 768px) {
  .categories__link {
    fs: 22px;
  }
}
@media only screen and (min-width: 1200px) {
  .categories__link {
    display: flex;
    fs: 16px;
    justify-content: space-between;
    align-items: center;
    ta: left;
  }
}
@media only screen and (min-width: 1440px) {
  .categories__link {
    pa: 12px;
    fs: 18px;
  }
}
@media only screen and (min-width: 1680px) {
  .categories__link {
    pa: 15px 40px;
    fs: 22px;
  }
}
.categories__link:hover {
  text-decoration: none;
  color: #26aded;
}
@media only screen and (min-width: 1200px) {
  .categories__link:hover {
    bg: #fff;
    transition: 0.3s all ease;
    pl: 20px;
  }
}
@media only screen and (min-width: 1680px) {
  .categories__link:hover {
    pl: 48px;
  }
}
.categories__link:hover .categories__icon img:first-child {
  display: none;
}
.categories__link:hover .categories__icon img:last-child {
  display: block;
}
.categories__icon {
  display: none;
}
@media only screen and (min-width: 1200px) {
  .categories__icon {
    display: block;
    h: 30px;
  }
}
@media only screen and (min-width: 1680px) {
  .categories__icon {
    h: 45px;
  }
}
.categories__icon img {
  h: 100%;
}
.categories__icon img:last-child {
  display: none;
}
.grid-sizer,
.grid-item {
  w: 100%;
}
@media screen and (max-width: 992px) and (min-width: 544px) and (orientation: portrait) {
  .grid-sizer,
  .grid-item {
    w: 50%;
  }
}
@media (max-width: 992px) and (min-height: 400px) and (orientation: landscape) {
  .grid-sizer,
  .grid-item {
    w: 50%;
  }
}
@media (max-width: 992px) and (max-height: 400px) and (orientation: landscape) {
  .grid-sizer,
  .grid-item {
    w: 50%;
  }
}
@media (max-width: 1200px) and (min-width: 993px) {
  .grid-sizer,
  .grid-item {
    w: 33.33%;
  }
}
@media only screen and (min-width: 1200px) {
  .grid-sizer,
  .grid-item {
    w: 33.33%;
  }
}
.grid-item {
  h: auto;
  overflow: hidden;
  cursor: pointer;
}
.grid-item img {
  transition: 1s all ease;
  display: block;
  w: 100%;
  h: 100%;
}
@media only screen and (min-width: 1200px) {
  .grid-item:hover img {
    transition: 1s all ease;
    transform: scale(1.2);
  }
}
.gallery {
  mt: 56px !important;
  h: calc(100% - 56px) !important;
}
@media only screen and (min-width: 768px) {
  .gallery {
    mt: 103px !important;
    h: calc(100% - 103px) !important;
  }
}
@media only screen and (min-width: 1200px) {
  .gallery {
    mt: 0 !important;
    h: 100% !important;
  }
}
.gallery--scroll {
  overflow: auto;
}
.gallery--scroll::-webkit-scrollbar {
  w: 0;
}
.gallery--scroll::-webkit-scrollbar-thumb {
  background: transparent;
}
.gallery,
.gallery__wrapper,
.gallery .fancybox {
  w: 100%;
  h: 100%;
}
.gallery__content {
  ta: left;
  transition: 0.3s height ease;
  bg: rgba(0,0,0,0.8);
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  w: 100%;
  box-sizing: border-box;
  h: 100%;
  pa: 10px 15px 10px;
  h: 90px;
}
@media only screen and (min-width: 768px) {
  .gallery__content {
    h: 107px;
  }
}
@media only screen and (min-width: 1200px) {
  .gallery__content {
    h: 92px;
    pt: 20px;
  }
}
@media only screen and (min-width: 1680px) {
  .gallery__content {
    h: 107px;
    pb: 6px;
  }
}
.gallery__info {
  h: 100%;
  overflow: hidden;
}
.gallery__item {
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: relative;
  w: 100%;
}
@media (max-width: 767px) {
  .gallery__item {
    h: 220px !important;
  }
}
@media screen and (max-width: 992px) and (min-width: 544px) and (orientation: portrait) {
  .gallery__item {
    w: 50% !important;
    h: 33.33% !important;
  }
}
@media (max-width: 992px) and (min-height: 400px) and (orientation: landscape) {
  .gallery__item {
    w: 50% !important;
    h: 50% !important;
  }
}
@media (max-width: 992px) and (max-height: 400px) and (orientation: landscape) {
  .gallery__item {
    w: 50% !important;
    h: 100% !important;
  }
}
@media (max-width: 1200px) and (min-width: 993px) {
  .gallery__item {
    w: 33.33% !important;
    h: 33.33% !important;
  }
}
@media only screen and (min-width: 1200px) {
  .gallery__item {
    w: 33.33% !important;
    h: 33.33%;
  }
}
.gallery__item--pointer {
  cursor: pointer;
}
.gallery__item--pointer img {
  transition: 1s all ease;
}
@media only screen and (min-width: 1200px) {
  .gallery__item--pointer:hover img {
    transition: 1s all ease;
    transform: translate(-50%, -50%) scale(1.2);
  }
}
@media only screen and (min-width: 768px) {
  .gallery__item--content:hover .gallery__content {
    h: 100%;
    transition: 0.3s height ease;
  }
}
.gallery__item--content:hover .gallery__heading,
.gallery__item--content:hover .gallery h2 {
  h: 66px;
}
@media only screen and (min-width: 768px) {
  .gallery__item--content:hover .gallery__heading,
  .gallery__item--content:hover .gallery h2 {
    h: 78px;
  }
}
@media only screen and (min-width: 1200px) {
  .gallery__item--content:hover .gallery__heading,
  .gallery__item--content:hover .gallery h2 {
    h: 62px;
  }
}
@media only screen and (min-width: 1440px) {
  .gallery__item--content:hover .gallery__heading,
  .gallery__item--content:hover .gallery h2 {
    h: 78px;
  }
}
@media only screen and (min-width: 1680px) {
  .gallery__item--content:hover .gallery__heading,
  .gallery__item--content:hover .gallery h2 {
    h: 78px;
  }
}
.gallery__item--main:after {
  content: '';
  position: absolute;
  w: 100%;
  h: 100%;
  top: 0;
  left: 0;
  bg: rgba(0,0,0,0.8);
}
.gallery__item img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.gallery__item img.is-absolute {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
}
.gallery__item img.is-fit {
  max-h: 500px;
  object-fit: cover;
}
.gallery__wrapper {
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
}
.gallery__heading p {
  ma: 0;
}
.gallery__heading,
.gallery h2 {
  color: #fff;
  ma: 0;
  h: 66px;
  cursor: inherit;
  overflow: hidden;
  letter-spacing: 0.77px;
  fs: 16px;
  mb: 10px;
}
@media only screen and (min-width: 768px) {
  .gallery__heading,
  .gallery h2 {
    fs: 22px;
    lh: 26px;
    h: 78px;
  }
}
@media only screen and (min-width: 1200px) {
  .gallery__heading,
  .gallery h2 {
    h: 100%;
    mb: 10px;
    lh: 20px;
    fs: 16px;
  }
}
@media only screen and (min-width: 1680px) {
  .gallery__heading,
  .gallery h2 {
    fs: 22px;
    lh: 26px;
    mb: 40px;
  }
}
.gallery__date {
  display: none;
}
@media only screen and (min-width: 1200px) {
  .gallery__date {
    display: block;
    fs: 10px;
    color: #fff;
    letter-spacing: 0.77px;
    position: absolute;
    top: 7px;
    right: 15px;
  }
}
.gallery__title {
  color: #fff;
  z-index: 2;
  ma: 0;
  ta: center;
  fs: 22px;
}
@media only screen and (min-width: 768px) {
  .gallery__title {
    fs: 30px;
  }
}
@media only screen and (min-width: 1000px) {
  .gallery__title {
    fs: 48px;
  }
}
.gallery__text {
  font-size: 14px;
  cursor: inherit;
  fw: 300;
  color: #fff;
  letter-spacing: 0.42px;
}
@media only screen and (min-width: 1440px) {
  .gallery__text {
    font-size: 16px;
  }
}
.gallery__text p {
  cursor: inherit;
}
.article {
  position: relative;
  bg: #000;
}
.article:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  h: 100%;
  w: 100%;
  bg-color: rgba(0,0,0,0.7);
}
@media only screen and (min-width: 1200px) {
  .article:after {
    display: none;
  }
}
.article__title {
  box-sizing: border-box;
  ma: 0 auto;
  mb: 20px;
  fs: 16px;
  color: #46a0ff;
  lh: 1.4;
}
@media only screen and (min-width: 1200px) {
  .article__title {
    fs: 20px;
    lh: 1.3;
    mb: 40px;
  }
}
@media only screen and (min-width: 1440px) {
  .article__title {
    fs: 26px;
  }
}
.article h2 {
  color: #fff;
}
.article__part {
  display: flex;
  justify-content: center;
  pa: 0 2.5vw;
  ma: 50px 2.5vw 30px 2.5vw;
  h: calc(100% - 56px) !important;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
}
@media only screen and (min-width: 768px) {
  .article__part {
    h: calc(100% - 53px) !important;
  }
}
@media only screen and (min-width: 1200px) {
  .article__part {
    pl: 35.5%;
    h: 100% !important;
    ma: 50px auto 50px auto;
  }
}
.article__image {
  position: absolute;
  top: 0;
  left: 0;
  h: 100%;
  bg-position: 50%;
  w: 100%;
  bg-size: cover;
  bg-repeat: no-repeat;
}
@media only screen and (min-width: 1200px) {
  .article__image {
    w: 32.5%;
  }
}
.article__wrapper {
  h: 100%;
  overflow: auto;
  max-width: 1100px;
  pa: 0 10px;
}
.article__wrapper::-webkit-scrollbar {
  w: 4px;
}
@media only screen and (min-width: 1200px) {
  .article__wrapper::-webkit-scrollbar {
    w: 12px;
  }
}
.article__wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}
.article__wrapper::-webkit-scrollbar-thumb {
  background: #343434;
  border-radius: 10px;
}
.article__content {
  font-family: 'Open Sans', Tahoma;
  color: #fff;
  float: left;
  font-weight: 300;
  font-size: 16px;
}
@media only screen and (min-width: 768px) {
  .article__content {
    pr: 16px;
  }
}
@media only screen and (min-width: 1440px) {
  .article__content {
    fs: 18px;
  }
}
@media only screen and (min-width: 1680px) {
  .article__content {
    fs: 20px;
  }
}
#bingc-phone-button:hover svg.bingc-phone-button-circle circle.bingc-phone-button-circle-inside {
  fill: transparent !important;
}
.scroll-down {
  display: none;
}
@media only screen and (min-width: 1200px) {
  .scroll-down {
    cursor: pointer;
    display: block;
    border: 3px solid #fff;
    border-radius: 50px;
    w: 44px;
    h: 90px;
    display: flex;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }
}
.scroll-down__image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: scrollDown 1s infinite ease-out;
}
@-moz-keyframes scrollDown {
  0% {
    transform: translate(-50%, calc(-50% - 5px));
  }
  25% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(-50%, calc(-50% + 5px));
  }
  75% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-50%, calc(-50% - 5px));
  }
}
@-webkit-keyframes scrollDown {
  0% {
    transform: translate(-50%, calc(-50% - 5px));
  }
  25% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(-50%, calc(-50% + 5px));
  }
  75% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-50%, calc(-50% - 5px));
  }
}
@-o-keyframes scrollDown {
  0% {
    transform: translate(-50%, calc(-50% - 5px));
  }
  25% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(-50%, calc(-50% + 5px));
  }
  75% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-50%, calc(-50% - 5px));
  }
}
@keyframes scrollDown {
  0% {
    transform: translate(-50%, calc(-50% - 5px));
  }
  25% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(-50%, calc(-50% + 5px));
  }
  75% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(-50%, calc(-50% - 5px));
  }
}
body.uct-tower-page {
  background-attachment: fixed;
}
.tower-content {
  mb: 70px;
  ta: justify;
  color: #fff;
  letter-spacing: 2px;
  font-weight: 300;
  fs: 16px;
}
@media only screen and (min-width: 1200px) {
  .tower-content {
    pr: 0;
  }
}
@media only screen and (min-width: 1440px) {
  .tower-content {
    fs: 18px;
  }
}
@media only screen and (min-width: 1680px) {
  .tower-content {
    fs: 20px;
  }
}
.tower-content h1,
.tower-content h2 {
  box-sizing: border-box;
  ma: 0 auto;
  mb: 20px;
  fs: 16px;
  lh: 1.4;
  color: #46a0ff;
}
@media only screen and (min-width: 768px) {
  .tower-content h1,
  .tower-content h2 {
    mb: 30px;
  }
}
@media only screen and (min-width: 1200px) {
  .tower-content h1,
  .tower-content h2 {
    mb: 40px;
    fs: 20px;
  }
}
@media only screen and (min-width: 1440px) {
  .tower-content h1,
  .tower-content h2 {
    fs: 26px;
  }
}
.pointer-none {
  pointer-events: none;
}
@media only screen and (min-width: 1200px) {
  .uct-tower-page::-webkit-scrollbar {
    w: 15px;
  }
}
.uct-tower-page::-webkit-scrollbar-track {
  border-radius: 10px;
}
.uct-tower-page::-webkit-scrollbar-thumb {
  background: #343434;
  border-radius: 10px;
}
.fancybox-wrap {
  w: 95% !important;
  left: 50% !important;
  h: calc(100% - 30px) !important;
  transform: translateX(-50%);
}
@media only screen and (min-width: 1200px) {
  .fancybox-wrap {
    w: 85% !important;
  }
}
.fancybox-inner {
  w: calc(100% - 30px) !important;
}
.fancybox-skin,
.fancybox-skin div {
  h: 100% !important;
}
.fancybox-inner {
  w: 100% !important;
}
.fancybox-inner div:last-child {
  display: flex;
  align-items: center;
}
.uct-main-wrapper--text {
  pa: 0 !important;
  height: auto !important;
  w: calc(100% - 30px) !important;
  ml: auto;
  mr: auto;
  w: 100%;
}
@media only screen and (min-width: 1200px) {
  .uct-main-wrapper--text {
    w: 65% !important;
    mr: 2.5vw;
  }
}
.swiper-container-vertical>.swiper-wrapper {
  flex-direction: row !important;
}
