.lang-switcher
  position relative
  z-index 2
  &__content
    display none
    position absolute
  &__wrapper
    display flex
    flex-direction column
    justify-content center
  &__item
    cursor pointer
    color #fff
    text-transform uppercase
    bg-color #000
    fw 900
    pa 5px 0
    display flex
    align-items center
    justify-content center
    border-left 2px solid $blue
    border-right 2px solid $blue
    transition .3s all ease
    w 26px
    h 26px
    fs 11px
    +media(from('xl'))
      w 36px
      h 36px
      color $blue
    &:last-child
      border-bottom 2px solid $blue
      border-bottom-left-radius 50%
      border-bottom-right-radius 50%
    &.is-current
      border 2px solid #000
      border-radius 50%
      +media(from('xl'))
        border 2px solid $blue
        position relative
      &:after
        +media(from('xl'))
          content ''
          position absolute
          bottom -16px
          border 6px solid transparent
          border-top-color $blue
          left 50%
          transform translateX(-50%)
    &.is-active
      border-bottom-left-radius 0
      border-bottom-right-radius 0
      border-bottom 0
      transition .3s all ease
      +media(from('xl'))
        border-bottom 0
      &.is-current
        border-color $blue