.play-button
  display none
  position relative
  +media(from('xl'))
    z-index 999
    cursor pointer
    h 22%
    w 11%
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    display flex
    justify-content center
    align-items center
    transition 1s all ease
    &:hover
      transition 1s all ease
      transform translate(-50%, -50%) scale(1.1)
  img
    h 100%